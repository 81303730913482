import "./header.scss";
import { HeaderTop } from "./header-top/header-top";
import { HeaderMain } from "./header-main/header-main";
import { HeaderNavigation } from "./header-navigation/header-navigation";
import { HeaderValueProposition } from "./header-value-proposition/header-value-proposition";
import { getComponentHeaderConfig } from "../scripts/component-header-config";
import { getUserData } from "../scripts/user-data";
import switchAccount from "../scripts/switch-account/switch-account";
import refreshToken from "../scripts/refresh-token";

import { reportError } from "../scripts/error-reporting";
import "../browser-message/browser-message";
import "../site-message/site-message";

import { h } from "../_common/jsx-dom.svg.js";

import jsCookie from "js-cookie";
import maxUserNotificationCenter from "../scripts/notification-center/max-user-notification-center.js";
import { setCacheUserData } from "../scripts/user-data/cache-user-data.js";
import { setCacheComponentHeaderConfig } from "../scripts/component-header-config/cache-component-header-config.js";

const TAG = "rg-header";
const showMaxCartDefaultValue = true;

class Header extends HTMLElement {

    _ele;
    _headerTop;

    constructor() {
        super();
    }

    async _refreshHeaderTop() {
        setCacheUserData(null);
        setCacheComponentHeaderConfig(null);

        const config = await getComponentHeaderConfig();
        const newUserData = await getUserData();
        const newData = config.header;

        const newHeaderTop = new HeaderTop(newUserData, newData);
        this._ele.replaceChild(newHeaderTop, this._headerTop);

        try{ switchAccount.init(); } catch(e) { reportError(e); }
        
        return newHeaderTop;
    }

    connectedCallback() {

        (async() => {
            this._addFavicon();
            const config = await getComponentHeaderConfig();
            const userData = await getUserData();
            const data = config.header;
            const features = config.features;
            const theme = config.theme;

            this._ele = (
                <header id="page-header" class={config.theme + " page-header-wrapper"}>
                    {this._createElements(userData, data, features, theme)}
                </header>
            );

            this.appendChild(this._ele);

            this.appendChild(
                <rg-browser-message></rg-browser-message>
            );

            this.appendChild(
                <rdm-site-message></rdm-site-message>
            );

            this.initScroll();
        
            try{ switchAccount.init(); } catch(e) { reportError(e); }

            try{ refreshToken.init(); } catch(e) { reportError(e); }

            try{ maxUserNotificationCenter.init(userData); } catch(e) { reportError(e); }

            document.addEventListener("refreshHeaderTop", async () => {
                this._headerTop = await this._refreshHeaderTop()
            })
        })();
    }

    _addFavicon() {
        const existingLink = document.querySelector("link[rel='icon']");
        if (existingLink) {
            document.head.removeChild(existingLink);
        }

        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/x-icon';
        link.href = '/resources/themes/images/icons/favicon.ico';
        document.head.appendChild(link);
    }

    _showMaxCart() {
        const showMaxCartAttributeValue = this.getAttribute("show-max-cart");

        return showMaxCartAttributeValue ? showMaxCartAttributeValue === "true" : showMaxCartDefaultValue;
    }

    _showPunchoutProductList() {
      const isMaxLite = jsCookie.get('theme-override') === 'MAX-LITE';
      const isPunchout = !!jsCookie.get('cart-punchout-session');
      const showProductList = jsCookie.get('punchout-feature-product-list') === 'true';
      return isMaxLite && isPunchout && showProductList;
    }

    _showPunchoutBranchQuotes() {
      const isMaxLite = jsCookie.get('theme-override') === 'MAX-LITE';
      const isPunchout = !!jsCookie.get('cart-punchout-session');
      const showBranchQuote = jsCookie.get('punchout-feature-branch-quote') === 'true';
      return isMaxLite && isPunchout && showBranchQuote;
    }

    _showNotificationCenter(isMaxUserWithLinkedAccounts) {
      return isMaxUserWithLinkedAccounts;
    }

    _createElements(userData, data, features, theme) {
        let elements = [];
        this._headerTop = new HeaderTop(userData, data);

        elements.push(
            this._headerTop,
            new HeaderMain(userData, features, data.logo, {
                showMaxCart: this._showMaxCart(),
                showPunchoutProductList: this._showPunchoutProductList(), 
                showPunchoutBranchQuotes: this._showPunchoutBranchQuotes(), 
                showNotificationCenter: this._showNotificationCenter(userData.isMaxUser && !userData.isUnlinkedTradeUser)
                }),
            new HeaderNavigation(userData, data, theme),
            new HeaderValueProposition(data)
        );

        return elements;
    }

    initScroll() {
        let scrollTop = 0;
        let lastScrollPos = 0;
        let header = document.getElementById('page-header');
        let scrollUpAmount = 0;
        let ticking = false;

        // sanity check if header does not exist
        if (header === null || header === undefined) return;

        function toggleHeader() {
            // reset the tick so we can
            // capture the next onScroll
            ticking = false;

            // Check if scroll up or down
            if(scrollTop > lastScrollPos) {
                scrollUpAmount = 0;
                // check if scroll down has cleared the header height
                if(scrollTop > header.offsetHeight) {
                    header.classList.add('hide-menu');
                }
            } 
            else {
                scrollUpAmount += (lastScrollPos - scrollTop);
                // check if scroll up has cleared the header height
                if(scrollUpAmount > header.offsetHeight) {
                    header.classList.remove('hide-menu');
                }
            }

            lastScrollPos = scrollTop;
        }

        function onScroll() {
            scrollTop = window.pageYOffset || document.body.scrollTop;
            requestTick();
        }

        function requestTick() {
            // Reference: https://css-tricks.com/debouncing-throttling-explained-examples/
            if(!ticking) {
                requestAnimationFrame(toggleHeader);
            }
            ticking = true;
        }

        window.addEventListener('scroll', onScroll, false);
    }
}

customElements.define(TAG, Header);
